import React from 'react'
import Header from '../component/Navbar/Header'
import Write from '../component/Write/Write'

export default function Writepage() {
  return (
    <div>
      <Header />
      <Write />
    </div>
  )
}
