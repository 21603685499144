import React, { useRef, useEffect } from 'react';
import Quill from 'quill';
import 'quill/dist/quill.snow.css';

const Text = () => {
  const editorRef = useRef(null);

  useEffect(() => {
    const quill = new Quill(editorRef.current, {
      theme: 'snow',
    });

    // Load saved data
    const savedData = "<p>Saved content goes here</p>";
    quill.root.innerHTML = savedData;

    // Save data
    quill.on('text-change', () => {
      const content = quill.root.innerHTML;
      console.log(content);
      // Save content to your server or local storage
    });
  }, []);

  return <div ref={editorRef}></div>;
};

export default Text;
